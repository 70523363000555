<template>
  <div id="qrbox" :class="[
      _position,
      _size,
      _hidden,
      {darkmode: darkmode}]">
    <div class="top">
      <div class="scan-me">
        {{ $t('system.scanMe') }}
      </div>
      <div class="qr">
        <VueQRCodeComponent :text="fullurl" error-level="L"></VueQRCodeComponent>
      </div>
    </div>

    <div class="bottom">{{ shorturl }}</div>
  </div>
</template>

<script>
import VueQRCodeComponent from "vue-qrcode-component";
export default {
  components: { VueQRCodeComponent },
  props: {
    position: {
      type: String,
      required: false, // center, left, right
      default: "",
    },
    size: {
      type: String,
      required: false, // large, small
      default: ""
    },
    darkmode: {
      type: Boolean,
      default: false,
    },
    hidden: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      shorturl: "",
      fullurl: "",
    };
  },
  computed: {
    coso(){
      return this.$store.getters["space/config"].experience.qrStandyBy
    },
    _hidden(){
      if(this.$store.getters["space/config"].experience.qrStandyBy.position == 'hidden') return "hidden";
      if(this.hidden) return "hidden";
      return ""
    },
    _position() {
      if (this.position == "") {
        if (this.$store.state.space.players.length == 0) {
          return this.$store.getters["space/config"].experience.qrStandyBy.position || "center";
        }
        return "left";
      } else {
        return this.position;
      }
    },
    _size() {
      if (this.size == "") {
        if (this.$store.state.space.players.length == 0){
          return this.$store.getters["space/config"].experience.qrStandyBy.size || "large";
        }
        return "small";
      } else {
        return this.size;
      }
    },
  },
  mounted() {
    this.fullurl = window.location.href;
    let urlfirstpart = window.location.protocol + "//";
    this.shorturl = window.location.href.replace(urlfirstpart, "");
    this.fullurl = this.fullurl.replace("/spaces", "");
    this.shorturl = this.shorturl.replace("/spaces", "");
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/variables.scss';
@import '@/styles/mixins.scss';

@mixin setSize($size) {
  width: $size;
  height: $size;

  &.left {
    left: calc(($size/2) - 1px);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    top: 65%;

    &.hidden {
      left: -$size;
    }

    .bottom {
      border-bottom-left-radius: 0;
    }
  }

  &.right {
    left: calc(100vw - ($size/2) + 1px);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    top: 65%;

    &.hidden {
      left: calc(100vw + $size);
    }

    .bottom {
      border-bottom-right-radius: 0;
    }
  }
}

#qrbox {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  @include blurBox();

  display: flex;
  flex-direction: column;
  justify-content: space-around;  
  $border-radius: 6px;
  $transition: all 0.7s cubic-bezier(0.21, -0.22, 0.3, 1);
  transition: $transition;

  .top {
    flex: 1;
    font-size: 2.5em;
    transition: $transition;

    .scan-me {
      padding: 0.8em;
      font-weight: bold;
    }
  }

  .qr {
    transition: $transition;
    width: 256px;
    margin: 0 auto;
    padding: 18px;
    background: $white-bis;
    border-radius: $border-radius;
  }

  .bottom {
    background-color: rgba(#ECECEC,0.5);
    border-radius: 0 0 $border-radius $border-radius;
    transition: $transition;
  }

  &.large {
    @include setSize(500px);

    .bottom {
      padding: 15px;
      font-size: 1.3em;
    }

  }

  &.small {
    @include setSize(250px);
    height: 240px;

    .top {
      font-size: 1rem;
      padding: 3px;
    }

    .qr {
      width: 130px;
    }

    .bottom {
      padding: 5px 0 8px;
      font-size: 0.9rem;
    }
  }

  &.darkmode {

    background-color: rgba($black-bis,0.8);
    color: $white-bis;

    .bottom {
      background-color: rgba($black-bis,0.5);
    }

  }
}
</style>
